import React from "react"
import styled from "styled-components"
import theme from "../general/theme"
import { GreatPrimer, SmallPrint } from '../typography';
const { gray7, agewellRed } = theme.colours;

const PersonContactCard = styled.div`
  margin-bottom: 2rem;
`;

function getPersonContact(x) {
  let id = x.id || '';
  let name = x.name || '';
  let PersonTitle = x.PersonTitle || '';
  let affiliation = x.affiliation || '';
  let email = x.email || null;

  return (
    <PersonContactCard key={id} id={id}>
      <GreatPrimer color={gray7} mb="0.8rem">{name}</GreatPrimer>
      <SmallPrint color={gray7} mb="0.6rem">{PersonTitle}</SmallPrint>
      <SmallPrint color={gray7} mb="0.6rem">{affiliation}</SmallPrint>
      <SmallPrint color={agewellRed} mb="0.6rem">{email}</SmallPrint>
    </PersonContactCard>
  )
}

export default getPersonContact;
import React from "react"
import styled from "styled-components"
import theme from "../general/theme"
import Layout from "../components/Layout"
import { StaticQuery, graphql } from "gatsby"
import Box from "../elements/Box"
import {
  Trafalgar,
  SmallCap,
} from '../typography';
import getPersonContact from "../utilities/getPersonContact"
const { white, gray8 } = theme.colours;

const ProjectSection = styled.section`
  .desc {
    text-align: justify;
  }
`;
  
  const GridContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr; 
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-areas: 
  "name name name name"
  "projectLeaders projectLeaders wpCoordinators wpCoordinators";
  // background-image: linear-gradient(160deg, rgb(207, 31, 50) 4%, rgb(255, 255, 255) 100%);
`;


// const serializers = {
//   types: {
//     description: props => (
//     <BodyCopy className="desc">{props.children}</BodyCopy>
//     )
//   }
// }

const ContactPage = ({ data }) => {
  const projects = data.allSanityResearchProject.edges;
  const wpProjects = projects.filter(i => i.node._rawResearchProjectCategory[0].name === 'WP' ? i : false)
  const coreProjects = projects.filter(i => i.node._rawResearchProjectCategory[0].name === 'Core' ? i : false)
  const otherProjects = projects.filter(i => i.node._rawResearchProjectCategory[0].name === 'Other' ? i : false)

  function projectCard (project) {
    let id = project.node.id || '';
    let name = project.node.name || '';
    let projectLeaders = project.node._rawProjectLeaders || null;
    let wpCoordinators = project.node._rawWpCoordinators || null;
    let projectLeadersTitle = 'PROJECT LEADERS'
    let WPTitle = 'WP LEADERS'

      return (
        <ProjectSection key={id}>
          <Box p="4rem" background={white} borderRadius="8px" mb="4rem">
            <GridContainer>
              <>
                <Box gridArea="name"><Trafalgar color={gray8}>{name}</Trafalgar></Box>
                <Box gridArea="projectLeaders">
                  <>
                    { projectLeaders && (<SmallCap color={gray8} mb="0.8rem">{id === "8862751a-cd24-5d58-8c4e-b64ef4738aac" ? WPTitle : projectLeadersTitle}</SmallCap>) }
                    { projectLeaders && projectLeaders.map(getPersonContact) }  
                  </>
                </Box>
                <Box gridArea="wpCoordinators">
                  <>
                    { wpCoordinators && (<SmallCap color={gray8}mb="0.8rem">WP COORDINATORS</SmallCap>) }
                    { wpCoordinators && wpCoordinators.map(getPersonContact) }
                  </>
                </Box>
              </>
            </GridContainer>
          </Box>
        </ProjectSection>
      )
    }

  return (
    <Layout>
      { wpProjects.map(projectCard) }
      { coreProjects.map(projectCard) }
      { otherProjects.map(projectCard) }
    </Layout>
  )
}


export default props => (
  <StaticQuery
    query={graphql`
      query ContactPage {
        allSanityResearchProject(sort: {fields: position}) {
          edges {
            node {
              _rawResearchProjectCategory(resolveReferences: {maxDepth: 10})
              _rawProjectLeaders(resolveReferences: {maxDepth: 10})
              name
              wp_coordinators {
                id
                name
                email
                affiliation
                PersonTitle
              }
              _rawWpCoordinators(resolveReferences: {maxDepth: 10})
            }
          }
        }
      }
    `}
    render={data => <ContactPage data={data} {...props} />}
  />
)

